<template>

  <el-container class="home-container">
    <el-header>
      <div class="header-left" >
        <img src="../../../public/logo.png" alt="">
        <span>欧菲德后台管理系统</span>

      </div>
      <div class="header-right">
        <el-badge :value="(goodsstock.length+goodsdate.length)" class="item" style="margin-right: 30px;" >
          <el-button size="small" @click="drawer = true" type="primary">通知</el-button>
        </el-badge>
        <el-button type="info" @click="logout">退出</el-button>
      </div>

    </el-header>
    <!--   页面主体   -->
    <el-container>
      <!--  侧边栏  -->
      <el-aside :width="isCollapse?'64px':'200px'">

        <div class="toggle-button" @click="toggleCollpapse">|||</div>
        <!--  侧边栏菜单区域  -->
        <el-menu background-color="#333744" text-color="#fff" active-text-color	="#409EFF" router :default-active="activePath"
            unique-opened :collapse="isCollapse" :collapse-transition	='false'>
          <!--  一级菜单-->
          <el-submenu :index="item.m_id + ''" v-for="item in menulist" :key="item.m_id">
            <!-- 一级菜单模板区-->
            <template slot="title">
              <!-- 图标 -->
              <i class="el-icon-location"></i>
              <!-- 文本 -->
              <span>{{ item.m_name }}</span>
            </template>
            <!-- 二级菜单 -->
            <el-menu-item :index="'/' + subitem.m_path" v-for="subitem in item.children" :key="subitem.m_id" @click="saveNavState('/' + subitem.m_path , subitem)">
              <template slot="title" >
                <!-- 图标 -->
                <i class="el-icon-menu"></i>

                <!-- 文本 -->
                <span>{{ subitem.m_name }}</span>
              </template>
            </el-menu-item>

          </el-submenu>


        </el-menu>
<!--      {{this.$store.state.count}}-->
      </el-aside>
<!--      <TagsView></TagsView>-->
<!--      <tags-view></tags-view>-->
<!--      <div style="width: 100%">-->

        <el-main>
<!--          <div style="padding-bottom: 10px;">-->
<!--            <el-tag-->
<!--                class="eltag" :class="hometab.is_gaol?'active':''"-->
<!--                @click="tabPaneClick(hometab.m_path,hometab)"-->
<!--                >-->
<!--                首页-->
<!--            </el-tag>-->
<!--            &lt;!&ndash;                :key="indexs"&ndash;&gt;-->

<!--            <el-tag-->
<!--                class="eltag" :class="item.is_gaol?'active':''" :key="index"-->
<!--                :disable-transitions="true"-->
<!--                v-for="(item,index) in tableTabs"-->
<!--                closable-->
<!--                @click="tabPaneClick('/' + item.m_path ,item)"-->
<!--                @close="handleClose(item)"-->
<!--            >-->
<!--              &lt;!&ndash;                :effect="item.is_gaol"&ndash;&gt;-->

<!--              {{item.m_name}}-->
<!--            </el-tag>-->
<!--          </div>-->
<!--          <el-tabs v-model="tableTabsValue" type="card" closable >-->
<!--            <el-tab-pane-->
<!--                v-for="(item,index) in tableTabs"-->
<!--                :label="item.title"-->

<!--                :key="index"-->
<!--                @click="tabPaneClick(item)"-->
<!--            >-->

<!--            </el-tab-pane>-->
<!--          </el-tabs>-->
<!--          <el-page-header @back="goBack" content="商品列表"></el-page-header>-->
          <keep-alive>
<!--            <router-view></router-view>-->
            <router-view v-if="$route.meta.keepAlive"></router-view>
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive"></router-view>


        </el-main>
<!--      </div>-->

    </el-container>
    <el-drawer
        title="系统提示列表"
        :visible.sync="drawer"
        :direction="direction"
        size="40%">
      <el-tabs type="border-card">
        <el-tab-pane label="安全库存提示">
          <el-table :data="goodsstock" height="80vh">
            <el-table-column property="ga_code" label="商品编码" width="150"></el-table-column>
            <el-table-column property="g_name" label="商品名称" width="200"></el-table-column>
            <el-table-column property="ga_name" label="商品规格"></el-table-column>
            <el-table-column property="ga_stock" label="商品库存"></el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="保质期提示">
          <el-table :data="goodsdate" height="80vh">
            <el-table-column property="ga_code" label="商品编码" width="150"></el-table-column>
            <el-table-column property="g_name" label="商品名称" width="200"></el-table-column>
            <el-table-column property="ga_name" label="商品规格"></el-table-column>
            <el-table-column property="ga_stock" label="商品库存"></el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>

    </el-drawer>
  </el-container>


  <!--    <router-view></router-view>-->
</template>

<script>
import '../../assets/css/global.css'
import TagsView from '../../components/TagsView'
// import {stasd} from '@/store/store'


export default {
  // components:{//把你引入的文件添加到组件了，不然不会生效的
  //   TagsView
  // },
  // computed: {
    // ...mapState({
    //   needTagsView: state => state.settings.tagsView,
    // }),
  // },
  // stasd,
  data() {
    return {
      //  左侧菜单数据
      menulist: [],
      isCollapse: false,
      //被激活的链接地址
      activePath:'',
      drawer: false,
      direction: 'rtl',
      goodsstock:[],
      goodsdate:[],
      tableTabsValue: 2,
      tableTabs:[],
      hometab:{
        m_name:'首页',
        m_path:'/',
        is_gaol:true
      },
      tabIndex: 2,
      //选中高亮
      glli:{},
      userinfo:{}
    }
  },
  created() {
    this.getMenuList()
    this.getgoodsstock()
    this.getgoodsdate()
    this.getuserinfo()
    this.activePath=window.sessionStorage.getItem('activePath')
    this.tableTabs=this.$store.state.tableTabs
  },
  methods: {
    handleClose(done) {
      for (let i=0;i<this.tableTabs.length;i++) {
        this.tableTabs[i].is_gaol=false
      }
      // console.log(this.glli.m_id,done.m_id)
      // if (this.glli.m_id===done.m_id){
      //   // console.log(this.glli)
      //   console.log(done)
      //   console.log(this.tableTabs)
      //   for (let i=0;i<this.tableTabs.length;i++){
      //     if (this.tableTabs[i].m_id==done.m_id){
      //       this.tableTabs.splice(i,1)
      //       this.tableTabs[this.tableTabs.length-1].is_gaol=true
      //       this.glli=this.tableTabs[this.tableTabs.length-1]
      //       this.$router.push(this.tableTabs[this.tableTabs.length-1].m_path)
      //       if(this.tableTabs.length==0){
      //         console.log(this.tableTabs.length)
      //         this.hometab.is_gaol=true
      //         this.$router.push('/')
      //         return false
      //       }
      //     }
      //   }
      //
      // }else {
      //   for (let i=0;i<this.tableTabs.length;i++) {
      //     if (this.tableTabs[i].m_id==done.m_id) {
      //       this.tableTabs.splice(i,1)
      //     }
      //     if (this.tableTabs[i].m_id==this.glli.m_id){
      //       this.tableTabs[i].is_gaol=true
      //     }
      //   }
      // }

      for (let i=0;i<this.tableTabs.length;i++) {

        if(done.m_id==this.tableTabs[i].m_id)  {
          // console.log(this.glli.is_gaol)
          // if(this.glli.m_id==this.tableTabs[i].m_id){
          //   this.tableTabs[i].is_gaol=true
          // }
          this.tableTabs.splice(i,1)

          // if (this.glli.m_id==this.tableTabs[i].m_id){
          // }
            this.tableTabs[i].is_gaol=true

          this.$router.push(this.tableTabs[this.tableTabs.length-1].m_path)
        }else if(this.glli.m_id==this.tableTabs[i].m_id){
          this.tableTabs[i].is_gaol=true
        }
      }
      for (let i=0;i<this.tableTabs.length;i++) {
        if (this.tableTabs[i].is_gaol==true){
          this.glli=this.tableTabs[i]
        }
      }
      window.sessionStorage.setItem('tabPath',this.tableTabs)
      this.$store.state.tableTabs=this.tableTabs
    },
    //获取商品低于安全库存
    async getgoodsstock(){
      const {data:res} =await this.$http.get('goods/getSafetyList')
      if (res.code != 200) return this.$message.error(res.msg)
      this.goodsstock=res.data
    },
    //获取商品低于安全库存
    async getgoodsdate(){
      const {data:res} =await this.$http.get('goods/getExpiredList')
      if (res.code != 200) return this.$message.error(res.msg)
      this.goodsdate=res.data
    },
    async getuserinfo(){
      const {data:res} = await this.$http.get('member/nowUser')
      if (res.code != 200) return this.$message.error(res.msg)
      console.log(res.data)
      this.$store.commit('setProductCode',res.data.m_id)
    },
    //退出登录
    async logout() {
      const confirmResult = await this.$confirm(
          '确认退出登录, 是否继续?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消退出登录')
      }
      let token= window.sessionStorage.getItem('token')
      const {data:res} = await this.$http.post('userExit', {token:token})
      if (res.code !== 200) return this.$message.error(res.msg)
      this.$message.success('退出登录成功')
      window.sessionStorage.clear()
      this.$router.push('/login')
    },
    //获取所有的菜单请求
    async getMenuList() {
      const {data: res} = await this.$http.get('menu/getList')
      if (res.code != 200) return this.$message.error(res.msg)
      this.menulist = res.data
      // console.log(res)
    },
    //点击按钮 实现菜单栏的展开
    toggleCollpapse() {
      this.isCollapse = !this.isCollapse
    },
    //保存链接的激活状态
    saveNavState(activePath,subitem){
      window.sessionStorage.setItem('activePath',activePath)


      this.activePath=activePath
    },
    tabPaneClick(activePath,item){
      // console.log("点击")
      this.glli=item
      if (activePath=='/'){
        this.tableTabs.forEach((item)=>{
          item.is_gaol=false
        })
        this.hometab.is_gaol=true
      }else{
        this.tableTabs.forEach((item)=>{
          item.is_gaol=false
        })
        this.hometab.is_gaol=false
        item.is_gaol=true
        // this.tableTabs.forEach((items)=>{
        //   if(item.m_id==items.m_id){
        //     items
        //   }
        // })
        // item.is_gaol='dark'
        this.$router.push({path: item.m_path})
        let newTabName = ++this.tabIndex + '';
        this.editableTabsValue = newTabName;

        window.sessionStorage.setItem('activePath',activePath)
        this.activePath=activePath
      }


    },
    removeTab(targetName){
      // let tabs = this.editableTabs;
      // let activeName = this.editableTabsValue;
      // if (activeName === targetName) {
      //   tabs.forEach((tab, index) => {
      //     if (tab.name === targetName) {
      //       let nextTab = tabs[index + 1] || tabs[index - 1];
      //       if (nextTab) {
      //         activeName = nextTab.name;
      //       }
      //     }
      //   });
      // }
      //
      // this.editableTabsValue = activeName;
      // this.editableTabs = tabs.filter(tab => tab.name !== targetName);
    }
  },

}
</script>

<style lang="less">
.el-drawer:focus {
  outline: none;
}
.el-drawer__header span:focus {
  outline: none;
}
.el-drawer__close-btn:focus{
  outline: none;
}
.el-drawer__body{
  padding: 30px;
}

body,html {
  height: 100vh;
  margin: 0;
  border: 0;
  padding: 0;
}

.home-container {
  height: 100vh;
}

.el-header {
  background: #373d41;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-left img {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}
section{
  min-height: calc(100vh - 60px);
}
.header-left span {
  font-size: 20px;
  color: white;
}

/*侧边栏*/
.el-aside {
  background: #333744;
  overflow: auto;
  .el-menu {
    border-right: none;
  }
}

.el-main {
  box-sizing: border-box;
  background: #EAEDF1;
  overflow: auto;
}

.toggle-button {
  background: #4a506a;
  font-size: 10px;
  line-height: 24px;
  color: #ffffff;
  text-align: center;
  letter-spacing: 0.2em;
}




</style>
<style>
.el-upload--text{
  float: left;
  width: 200px;
  height: 200px;
  border: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.el-upload img{
  width: 100%;
  height: 100%;
}
.upload-delete{
  position: relative;
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
}
.upload-delete i{
  font-size: 20px;
}
.eltag{
  margin-right: 5px;
}
.eltag.active{
  background: #409eff;
  color: #fff;
}
.eltag.active i{
  color: #fff;
}

</style>
